<template>
<div class="flex flex-col w-full mx-auto">
  <div ref='formdata'>
    <div v-if="!subscribe">
      <slot name="once-off"></slot>
    </div>
    <div v-if="subscribe">
      <slot name="monthly"></slot>
    </div>
  </div>

  <div v-if="banner && showLink" v-on:click="navigate" :class="linkButtonCls"><h5 class="mb-0 mr-4 border-b text-sm border-white">{{ linkText }}</h5> <img class="w-6 hidden md:block" src="/assets/images/globals/more-arrow.svg" alt="">  <img class="w-6 md:hidden" src="/assets/images/globals/more-arrow_dark.svg" alt=""> </div>

  <div :class="switchBlockClass">

      <template v-if="true">
          <div class="flex flex-1 items-center font-bold text-base h-6 px-1 cursor-pointer" :class="{'md:text-white': banner || (footer && !dark) }" v-on:click="setSubscribe(false)">
              <input type="checkbox" class="text-xl" :checked="!subscribe" :value="!subscribe" style="pointer-events:none;"/>
              <span class="ml-4">{{ text.action.once }}</span>
          </div>
          <div class="flex flex-1 items-center font-bold text-base h-6 px-1 cursor-pointer" :class="{'md:text-white': banner || (footer && !dark) }" v-on:click="setSubscribe(true)">
              <input type="checkbox" class="text-xl" :checked="subscribe" :value="subscribe" style="pointer-events:none;"/>
              <span class="ml-4">{{ text.action.monthly }}</span>
          </div>
      </template>
      <template v-else>
          <span v-on:click="setSubscribe(false)" :class="onceClass">{{ text.action.once }}</span>
          <span v-on:click="setSubscribe(true)" :class="monthlyClass">{{ text.action.monthly }}</span>
      </template>
  </div>

  <div :class="mainBlockClass">
      <div v-if="showQuickAmounts" :class="quickAmountsBlockClass" :style="`display: grid; grid-template-columns: repeat(${quickAmounts.length}, 1fr); grid-column-gap: 0.5rem`">
          <div :class="showCustom || amount != parseFloat(row.amount) ? quickAmountClass : selectedQuickAmountClass" :style="showCustom || amount != parseFloat(row.amount) ? buttonSecondaryStyle : buttonPrimaryStyle" v-on:click="setAmount(parseFloat(row.amount))" v-for="row in quickAmounts" :key="row.amount" >{{ row.label }}</div>
      </div>

      <div :class="donateBlockClass">
          <div class="mt-0 font-bold justify-center items-center relative z-20 flex-1 sm:w-auto" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 0.5rem">
              <input ref="amount" v-model="amount" :placeholder="text.action.placeholder" type="number" :class="'rounded-sm text-base text-center border-solid border pl-5 h-16 font-bold donate-field border-solid w-full ' + (showCustom ? '' : 'hidden ') + (dark ? 'text-gray-900 ' : footer ? 'bg-transparent text-white border-white ' : 'border-gray-900 text-gray-900 ')" />
              <div :class="'absolute left-0 border-top pl-2 text-base h-full flex items-center ' + (dark ? 'text-blue-500 ' : footer ? 'text-white ' : !banner ? 'text-gray-900' : '') + (showCustom ? '' : 'hidden ')" style="">{{ symbol }}</div>
              <span v-on:click="setShowCustom" v-if="!showCustom" :class="showCustomButtonClass" :style="buttonSecondaryStyle">{{ text.action.custom }}</span>
            <span v-on:click="showDonation" :class="donateButtonCls" :style="buttonPrimaryStyle">{{ text.action.donate }}</span>
          </div>
          <div v-if="!banner && showLink" v-on:click="navigate" :class="linkButtonCls"><h5 class="mb-0 mr-4 border-b border-white">{{ linkText }}</h5> <img class="w-8" src="/assets/images/globals/more-arrow.svg" alt=""> </div>
      </div>
  </div>
  <div class="flex flex-col md:flex-row lg:flex-col justify-end items-end">
    <popup-modal ref="modal" v-if="donation" :show="donation" v-on:close="hideDonation" class="sample" :close="close">
      <div class="max-w-lg w-screen px-6 pt-6 pb-16  bg-white">
        <div class="mb-6 border-b" v-if="!hideTitle">
          <h2 class="text-gray-800 text-center ">{{ title }}</h2>
          <div class="w-full text-gray-700 text-sm font-bold uppercase text-center mb-4" style="margin-top: -1.2rem">{{ subscribe ? text.action.monthly : text.action.once }}</div>
          <p v-if="taxDeductible" class="small italic text-center pb-4">
            NB: You will receive a tax-deductible receipt from our partner {{ partner }}
          </p>
        </div>
        <payment-form v-on:success="showConfirmation" :user="user" :show-giftaid="true" :giftaid="giftaid" :add-recaptcha="true/*hasRecapture*/" :consent="consent" :show-consent="showConsent" :stripe-key="currentForm.stripe" :payment-form="currentForm" :reuse="subscribe" v-on:failed="showError" :fields="passedFields" :project="project"
          :initial-amount="amount" :handle="handle" :title="title" :hidden-fields="extraFields" :submit-text="text.action.donate" :text="text">
        </payment-form>
      </div>
    </popup-modal>
    <popup-modal v-if="confirmation" :show="confirmation" v-on:close="hideConfirmation" :close="close">
      <div class="max-w-lg w-screen px-6 py-6 bg-white confirmation">
        <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
          <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="124.878" height="118.319" viewBox="0 0 124.878 118.319">
            <g id="Group_632" data-name="Group 632" transform="translate(2698.52 -2981.882)">
              <path id="Path_303" data-name="Path 303" d="M112.22,71.349s4.925-19.859,6.386-27.029,7.76-7.517,9.844-6.071,4.63,38.489,4.63,43.239,1.25,10.927-5.393,17.684-16.271,16.256-18.923,18.865-6.928,5.811-6.858,11.161,0,10.078,0,10.078H74.227l2.13-32.111,25.55-15.987Z"
                transform="translate(-2706.814 2960.926)" fill="#3f9de5" />
              <path id="Path_302" data-name="Path 302" d="M98.861,102.515s-7,8.249-23.513,8.249c.112.04.838-7.583.838-7.583s.563-20.066,22.675-19.3c.235,0,13.574-16.97,13.574-16.97s11.185,6.919,2.868,16.97S98.861,102.515,98.861,102.515Z" transform="translate(-2706.66 2964.939)"
                fill="#2f83c3" />
              <path id="Path_304" data-name="Path 304" d="M95.18,71.349S90.255,51.49,88.794,44.32s-7.76-7.517-9.844-6.071-4.63,38.489-4.63,43.239-1.25,10.927,5.393,17.684,16.271,16.256,18.923,18.865,6.928,5.811,6.858,11.161,0,10.078,0,10.078h27.679l-2.13-32.111-25.55-15.987Z"
                transform="translate(-2772.746 2960.926)" fill="#3f9de5" />
              <path id="Path_305" data-name="Path 305" d="M94.866,102.515s7,8.249,23.513,8.249c-.112.04-.838-7.583-.838-7.583s-.563-20.066-22.675-19.3c-.235,0-13.574-16.97-13.574-16.97s-11.185,6.919-2.868,16.97S94.866,102.515,94.866,102.515Z"
                transform="translate(-2759.227 2964.939)" fill="#2f83c3" />
              <ellipse id="Ellipse_304" data-name="Ellipse 304" cx="30.693" cy="30.693" rx="30.693" ry="30.693" transform="translate(-2666.682 2981.882)" fill="#f5f5f5" />
              <path id="Path_306" data-name="Path 306" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" transform="translate(-568 272)" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
          </svg>
          <h3 class="text-center">{{ text.messages.received.title }}</h3>
          <p class="text-center">{{ text.messages.received.desc }}</p>
          <div v-if="chainSignup && !user.firstName" class="mt-2 self-stretch">
              <div>
                  <p class="text-center">{{ text.messages.signup }}</p>
                  <div class="sm:ml-2 mt-4 flex-1 flex justify-center items-center relative z-20 w-full sm:w-auto">
                      <span v-on:click="showSignup" :class="donateButtonCls">{{ text.action.signup }}</span>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </popup-modal>
    <popup-modal v-if="error" :show="error" v-on:close="hideError" :close="close">
      <div class="flex flex-col items-cewnter justify-center max-w-lg w-screen px-6 py-6  bg-white">
        <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
          <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="119.455" height="119.455" viewBox="0 0 119.455 119.455">
            <g id="Group_634" data-name="Group 634" transform="translate(2880.717 -2986.847)">
              <circle id="Ellipse_305" data-name="Ellipse 305" cx="59.728" cy="59.728" r="59.728" transform="translate(-2880.717 2986.847)" fill="#f5f5f5" />
              <g id="Group_633" data-name="Group 633" transform="translate(9.682 8.682)">
                <line id="Line_191" data-name="Line 191" x2="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                <line id="Line_192" data-name="Line 192" x1="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
              </g>
            </g>
          </svg>
          <h3 class="text-center">{{ text.messages.error.title }}</h3>
          <p class="text-center">{{ text.messages.error.desc }}</p>
          <a class="mt-8 btn" href="mailto:it@example.com">{{ text.action.contact }}</a>
        </div>
      </div>
    </popup-modal>
  </div>
  <popup-modal :show="signup" v-on:close="hideSignup" :close="close">
      <user-signup :prefill="captured" class="popup-sign-up bg-white px-6 py-8" :text="userform"/>
  </popup-modal>
</div>
</template>

<script>
import EventBus from '../EventBus'

export default {
  props: {
    close: String,
    text: Object,
    title: String,
    hideTitle: Boolean,
    project: String,
    fields: Array,
    handle: String,
    footer: Boolean,
    dark: Boolean,
    primary: Boolean,
    banner: Boolean,
    user: Object,
    userform: Object,
    forms: Object,
    defaultAmount: Number,
    quickAmounts: Array,
    fundraiser: Boolean,
    segmentId: String,
    defaultSubscribe: Boolean,
    stripeKeyMonthly: String,
    stripeKeyOnceOff: String,
    taxDeductible: Boolean,
    showGiftaid: Boolean,
    showConsent: Boolean,
    consent: Array,
    giftaid: Object,
    partner: String,
    customDonate: String,
    userOptout: Boolean,
    noAmount: Boolean,
    showLink: Boolean,
    linkText: String,
    linkUrl: String,
    remote: Boolean,
  },
  data() {
    return {
      subscribe: this.defaultSubscribe,
      amount: this.defaultQuickAmount() || this.defaultAmount || null,
      symbol: window.currencySymbol,
      signup: false,
      donation: false,
      confirmation: false,
      extraFields: [],
      error: false,
      showCustom: !this.quickAmounts || this.quickAmounts.length <= 1,
      captured: {},
      chainSignup: this.text.chainSignup,
    }
  },
  created() {
    this.handle = this.handle.replace(".", "");
    console.log("Segment ID:", this.segmentId);
    console.log("Stripe Keys:", this.stripeKeyOnceOff, this.stripeKeyMonthly);
  },
  computed: {

    onceClass() {
        if (this.footer)
            return this.footerOnceClass
        else if (this.subscribe) {
            return this.banner ? "uppercase tracking-widest bg-white md:bg-transparent md:text-white md:border-white cursor-pointer flex-1 border border-solid rounded-r-sm border-gray-800 text-center px-2 py-2 one-off border-solid" :
              "uppercase tracking-widest border bg-white cursor-pointer flex-1 rounded-r-sm border-gray-900 text-center px-2 py-1 one-off border-solid"
        } else {
            return this.banner ? "uppercase tracking-widest flex-1 bg-gray-800 md:bg-white md:text-gray-800 md:border-white border border-gray-900 border-solid rounded-r-sm md:text-white text-center px-2 py-2 one-off" :
              "uppercase tracking-widest flex-1 bg-gray-800 border border-gray-900 rounded-r-sm text-white text-center px-2 py-1 one-off border-solid"
        }
    },
      footerOnceClass() {
          if (this.subscribe) {
              return !this.dark ? "uppercase tracking-widest cursor-pointer flex-1 border border-white rounded-r-sm text-white text-center px-2 py-1 one-off border-solid" :
                "uppercase tracking-widest bg-transparent text-gray-900 cursor-pointer flex-1 border rounded-r-sm border-black text-center px-2 py-1 one-off border-solid"
          } else {
              return !this.dark ? "uppercase tracking-widest flex-1 bg-white border border-white rounded-r-sm text-center px-2 py-1 one-off border-solid" :
                "uppercase tracking-widest flex-1 bg-gray-800 border border-gray-900 border-solid rounded-r-sm text-white text-center px-2 py-1 one-off"
          }
      },
      monthlyClass() {
          if (this.footer)
            return this.footerMonthlyClass
          else if (this.subscribe) {
            return this.banner ? "uppercase tracking-widest flex-1 border md:bg-white md:text-gray-800 md:border-white border-gray-900 border-solid rounded-l-sm bg-gray-800 md:text-white text-center px-2 py-2 monthly" :
              "uppercase tracking-widest flex-1 border border-gray-900 rounded-l-sm bg-gray-800 text-white text-center px-2 py-1 monthly"
          } else {
            return this.banner ? "uppercase tracking-widest bg-white md:bg-transparent md:text-white md:border-white cursor-pointer rounded-l-sm flex-1 border border-gray-900 border-solid text-center px-2 py-2 monthly border-solid" :
              "uppercase tracking-widest flex-1 border border-gray-900 rounded-l-sm bg-transparent text-gray-900 text-center px-2 py-1 monthly border-solid"
          }
      },
      footerMonthlyClass() {
          if (this.subscribe) {
              return !this.dark ? "uppercase tracking-widest flex-1 border border-white rounded-l-sm bg-white text-center px-2 py-1 monthly" :
                    "uppercase tracking-widest flex-1 border border-gray-900 border-solid rounded-l-sm bg-gray-800 text-white text-center px-2 py-1 monthly"
          } else {
              return !this.dark ? "uppercase tracking-widest cursor-pointer rounded-l-sm flex-1 border border-white text-white text-center px-2 py-1 monthly border-solid" :
                    "uppercase tracking-widest bg-transparent cursor-pointer rounded-l-sm flex-1 border border-gray-900 text-center px-2 py-1 monthly border-solid text-gray-900"
          }
      },
      switchBlockClass() {
          return this.banner ? "flex w-full tabs z-20 donate-shrink" :
                this.footer ? "mt-2 flex w-full tabs z-20" :
                this.fundraiser ? "hidden" :
                "mt-4 flex w-full text-sm"
      },
      mainBlockClass() {
          return this.banner ? "mt-2 bg-white px-2 py-2 rounded border-gray-300 flex flex-col" : "w-full flex flex-col"
      },
      quickAmountsBlockClass() {
          return this.fundraiser ? "hidden" :
                this.banner ? `mb-2 grid grid-flow-cols grid-rows-${this.quickAmounts && this.quickAmounts.length}` :
                `mt-2 grid grid-flow-cols grid-rows-${this.quickAmounts && this.quickAmounts.length}`;
      },
      quickAmountClass() {
          return this.dark ? "flex justify-center items-center cursor-pointer rounded flex-1 bg-gray-300 border-solid text-sm border-gray-500 border text-gray-500 text-center px-2 h-16" :
                  this.banner || this.footer ? "flex justify-center items-center cursor-pointer rounded flex-1 bg-gray-300 border-solid text-sm border-gray-500 border text-gray-500 text-center px-2 h-16" :
                  "flex justify-center items-center cursor-pointer rounded flex-1 bg-gray-300 border-solid text-sm border-gray-500 border text-gray-500 text-center px-2 h-16"

      },
      selectedQuickAmountClass() {
          return this.footer && !(this.primary || this.dark) ? "flex justify-center items-center cursor-pointer rounded flex-1 bg-white border-solid text-sm border-white border text-blue-600 text-center px-2 h-16" :
                  this.banner || this.footer ? "flex justify-center items-center cursor-pointer rounded flex-1 bg-blue-600 border-solid text-sm border-blue-700 border text-white text-center px-2 h-16" :
                  "flex justify-center items-center cursor-pointer rounded flex-1 bg-blue-600 border-solid text-sm border-blue-700 border text-white text-center px-2 h-16"
      },
      buttonSecondaryStyle() {
          return this.banner || this.footer ? "" :
            "background: var(--brand-stripe-button-secondary-bg); color: var(--brand-stripe-button-secondary-text); border-color: var(--brand-stripe-button-secondary-bg);"

      },
      buttonPrimaryStyle() {
          return this.banner || this.footer ? "" :
            "background: var(--brand-stripe-button-primary-bg); color: var(--brand-stripe-button-primary-text); border-color: var(--brand-stripe-button-primary-bg);"
      },
      donateBlockClass() {
          return this.banner ? "flex w-full flex-col" :
                this.fundraiser ? "my-2 flex w-full flex-col sm:flex-row" :
                "my-2 flex w-full flex-col sm:flex-row"
      },
      showCustomButtonClass() {
          return this.dark ? "donate-field cursor-pointer rounded w-full bg-gray-300 border text-lg border-gray-500 border border-solid text-gray-500 text-center px-2 h-16 flex justify-center items-center mr-2" :
                this.banner || this.footer ? "donate-field cursor-pointer w-full rounded bg-gray-300 border text-lg border-gray-500 border border-solid text-gray-500 text-center px-2 h-16 flex justify-center items-center mr-2" :
                "donate-field cursor-pointer w-full rounded bg-gray-300 border text-lg border-gray-500 border border-solid text-gray-500 text-center px-2 h-16 flex justify-center items-center"
      },
    donateButtonCls() {
      if (this.footer) {
        return this.primary ? "donate-field cursor-pointer rounded-sm w-full bg-blue-600 text-lg border border-solid border-blue-800 text-white text-center px-2 h-12 flex justify-center items-center" :
        "cursor-pointer rounded-sm flex-1 bg-white text-lg brand-text border-white text-white text-center px-2 h-16 flex justify-center items-center";
      } else if (this.showLink) {
        return "donate-field cursor-pointer w-full rounded-sm bg-blue-600 border-solid text-lg border-blue-800 border text-white text-center px-2 h-16 flex justify-center items-center";
      } else {
        return "donate-field cursor-pointer w-full rounded-sm bg-blue-600 border-solid text-lg border-blue-800 border text-white text-center px-2 h-16 flex justify-center items-center";
      }
    },
    linkButtonCls() {
      if (this.footer) {
        return "cursor-pointer rounded-sm flex-1 bg-transparent uppercase text-sm border border-white text-white text-center px-2 h-12 flex justify-center items-center";
      } else {
        return "cursor-pointer rounded-sm w-full font-bold bg-transparent text-base text-gray-900 md:text-white text-left flex justify-start items-center underline mt-2 mb-4";
      }
    },
    passedFields() {
      let extraNames = this.extraFields.map(({
        name
      }) => name)

      return this.fields.map((field) => {
          console.log(field.handle, this.user[field.handle])
          return {
              ...field,
              value: field.handle === "amount" ? this.amount :
                    field.handle === 'giftAidConsent' && this.user['giftAid'] ? this.user['giftAid'] > 0 :
                    this.user[field.handle] ?  this.user[field.handle] :
                    field.value
                }
      })
        .filter((field) => !extraNames.includes(field.name) && field.type !== "recaptcha")
    },
    hasRecapture() {
      return this.fields.some((field) => field.type === "recaptcha")
      },
      currentForm() {
          return this.subscribe ? this.forms.monthly : this.forms.once
      },
      showQuickAmounts() {
          return this.quickAmounts && this.quickAmounts.length > 1
      }

  },
  mounted() {
    if (this.$refs.formdata) {
      this.extraFields = []
      let specialInputs = this.$refs.formdata.getElementsByTagName('input')
      console.log("Currently ", specialInputs.length, "inputs")
      this.extraFields.push({
        name: "metadata-segment",
        value: this.segmentId,
        type: "hidden"
      })
      this.extraFields.push({
        name: "metadata-projectid",
        value: this.project,
        type: "hidden"
      })
      if (!this.userOptout) {
        this.extraFields.push({
          name: "optOut",
          handle: "optOut",
          value: 1,
          type: "hidden",
        })
      }

      for (var i = 0; i < specialInputs.length; i++) {
        var elem = specialInputs.item(i)

        if (elem.getAttribute("value")) {
          this.extraFields.push({
            value: elem.getAttribute("value"),
            name: elem.getAttribute("name"),
            type: "hidden",
          })
        }
      }
    }
  },
  methods: {
    updateFields() {
      console.log("Updating fields");
      if (this.$refs.formdata) {
        this.extraFields = []
        this.extraFields.push({
          name: "metadata-segment",
          value: this.segmentId,
          type: "hidden"
        })
        this.extraFields.push({
          name: "metadata-projectid",
          value: this.project,
          type: "hidden"
        })
        if (!this.userOptout) {
          this.extraFields.push({
            name: "optOut",
            handle: "optOut",
            value: 1,
            type: "hidden",
          })
        }
        let specialInputs = this.$refs.formdata.getElementsByTagName('input')
        console.log("Currently ", specialInputs.length, "inputs")
        for (var i = 0; i < specialInputs.length; i++) {
          var elem = specialInputs.item(i)

          if (elem.getAttribute("value")) {
            this.extraFields.push({
              value: elem.getAttribute("value"),
              name: elem.getAttribute("name"),
              type: "hidden",
            })
          }
        }
      }
    },
    toggleSubscribe() {
      this.subscribe = !this.subscribe;
      this.$nextTick(this.updateFields);
      if (this.subscribe) {
        console.log("Changing to monthly");
      } else {
        console.log("Changing to once-off");
      }
    },
    setSubscribe(subscribe) {
      this.subscribe = subscribe;
      this.$nextTick(this.updateFields);
      if (this.subscribe) {
        console.log("Changing to monthly");
      } else {
        console.log("Changing to once-off");
      }
    },
    showDonation() {
      if (this.amount) {
        if (this.remote) {
          EventBus.$emit('craft:remote-donate', {
            stripeKeyMonthly: this.stripeKeyMonthly,
            stripeKeyOnceOff: this.stripeKeyOnceOff,
            subscribe: this.subscribe,
            passedFields: this.passedFields,
            project: this.project,
            handle: this.handle,
            amount: this.amount,
            title: this.title,
            extraFields: this.extraFields,
            hasRecapture: this.hasRecapture,
            taxDeductible: this.taxDeductible,
            hideTitle: this.hideTitle,
            partner: this.partner,
            currentForm: this.currentForm,
            text: this.text,
            submitText: "Donate Now"
          })
        } else {
          this.donation = true;
          EventBus.$emit('craft:noscroll', this.$refs.modal)
        }
      }
    },
    hideDonation() {
      this.donation = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    showConfirmation(ev) {
      this.hideDonation();
      this.captured = ev.values;
      this.confirmation = true;
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    showError(ev) {
      this.hideDonation();
      this.error = true;
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    hideConfirmation() {
      this.confirmation = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    hideError() {
      this.error = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    navigate() {
      window.location = this.linkUrl
    },
    showSignup() {
        this.hideConfirmation();
        this.signup = true;
    },
    hideSignup() {
        this.signup = false;
    },
    defaultQuickAmount() {
        if (!this.quickAmounts || !this.quickAmounts.length) {
            return 0;
        }
        const defaultAmounts = this.quickAmounts.filter((amount) => amount.default);
        return defaultAmounts.length ? parseFloat(defaultAmounts[0].amount) : parseFloat(this.quickAmounts[0].amount)
    },
    setAmount(amount) {
        this.showCustom = false;
        this.amount = amount;
    },
    setShowCustom() {
        this.showCustom = true;
        if (this.$refs.amount) {
            console.log("Show text")
            this.$refs.amount.focus();
            this.$nextTick(() => {
                this.$refs.amount.select();
            })
        }
    }
  },
};
</script>
