
const freeform = async (fields, metadata, accept, reject, fail, final = () => {}) => {
    let paid = false;
    let pd;
    try {
        let data = {}
        let errors = {}
        fields.forEach(({handle, value}) => {
            if (handle)
                data[handle] = typeof(value) !== 'undefined' ? value : ''
        })

        console.log(JSON.stringify(data, null, 2))
        
        if (metadata.submissionId) {
            console.log("SETTING THE LINKEDSUBMISSION", metadata.submissionId)
            data.linkedSubmission = metadata.submissionId
        }

        let paymentfield = fields.filter(f => f.handle == 'paymentEmbed')
        for (let f of paymentfield) {
            let {
                handle,
                ref
            } = f;
            let attempt = ref.prepareSubmission(data)
            if (!attempt.success) {
                for (let key in attempt.errors) {
                    errors[key] = attempt.errors[key]
                }
                reject(errors)
                return;
            } else {
                //establishes the status var.
                let {formHash, freeform_payload, payment} = attempt.data;
                let freeform_action = attempt.data['freeform-action']
                f.value = JSON.stringify({
                    action: freeform_action,
                    type: 'freeform',
                    form: attempt.form,
                    data: {
                        formHash,
                        freeform_payload,
                        payment,
                        'freeform-action': freeform_action
                    },
                    status: 'incomplete'
                })

                console.log(f.value)

                let stripePayment = await new Promise((resolve, reject) => {
                    if (ref.currentForm.data.type !== 'single') {
                        let bd = ref.billingDetails
                        ref.stripe.createToken(ref.cardElem, {
                            name: bd.name,
                            address_line1: bd.address.line1,
                            address_line2: bd.address.line2,
                            address_city: bd.address.city,
                            address_state: bd.address.state,
                            address_country: bd.address.country,
                            address_zip: bd.address.postal_code,
                            email: bd.email,
                            phone: bd.phone,
                        })
                        .then(result => {
                            resolve({
                                error: result.error,
                                id: result.error ? undefined : result.token.id,
                            })
                        })
                    } else {
                        ref.stripe.createPaymentMethod({
                            type: 'card',
                            card: ref.cardElem,
                            billing_details: ref.billingDetails 
                        })
                        .then(result => {
                            resolve({
                                error: result.error,
                                id: result.error ? undefined : result.paymentMethod.id,
                            })
                        })
                    }
                })

                if (stripePayment.error) {
                    reject({payment: stripePayment.error})
                    return;
                }

                let parts = ref.buildData({
                    recaptcha: metadata.recaptcha,
                    payment: stripePayment.id,
                    csrf: {
                        csrfTokenName: window.csrfTokenName,
                        csrfTokenValue: window.csrfTokenValue
                    },
                    fields: data
                })

                let resp = await fetch(window.location, {
                    method: 'POST',
                    body: buildForm(parts.data, parts.action),
                    headers: {
                        Accept: 'application/json',
                        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                })

                if (resp.ok) {
                    let rr = await resp.json()

                    if (rr.formErrors.length) {
                        reject({}, rr.formErrors)
                        return
                    }

                    if (rr.success) {
                        paid = true
                        payment = rr
                        f.status = "success"
                        f.value = JSON.stringify({
                            type: 'freeform',
                            form: attempt.form,
                            submissionId: rr.submissionId,
                            status: "success",
                            submissionToken: rr.submissionToken,
                            success: rr.success,
                            finished: rr.finished
                        })
                        accept(rr)
                        return
                    } else if (rr.finished) {
                        let action = rr.actions[0]
                        let result = await new Promise((resolve, reject) => {
                            resolve(action.metadata.subscription ?
                                f.ref.stripe.handleCardPayment(action.metadata.payment_intent.client_secret, f.ref.cardElem):
                                f.ref.stripe.handleCardAction(action.metadata.payment_intent.client_secret))
                        }).catch(error => {
                            reject({}, error)
                            console.log(error)
                        })
                        console.log(result)
                        if (result.error) {
                            reject({}, result.error)
                        } else {
                            resp = await fetch(window.location, {
                                method: 'POST',
                                body: buildForm({...parts.data, payment: action.metadata.subscription ? action.metadata.subscription.id : action.metadata.payment_intent.id}, parts.action),
                                headers: {
                                    Accept: 'application/json',
                                    'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                                    'X-Requested-With': 'XMLHttpRequest'
                                },
                            })

                            if (resp.ok) {
                                rr = await resp.json()

                                if (rr.formErrors.length) {
                                    reject({}, rr.formErrors)
                                    return
                                }

                                if (rr.success) {
                                    paid = true
                                    payment = rr
                                    f.status = "success"
                                    f.value = JSON.stringify({
                                        type: 'freeform',
                                        form: attempt.form,
                                        submissionId: rr.submissionId,
                                        status: "success",
                                        submissionToken: rr.submissionToken,
                                        success: rr.success,
                                        finished: rr.finished
                                    })
                                    accept(rr)
                                    return
                                }
                            }
                        }
                    }
                }
            }
        }
    } catch (error) {
        if (paid) {
            accept(pd, error)
        } else {
            fail(error)
        }
    } finally {
        final()
    }
}

const buildForm = (data, action) => {
    let form = new FormData();
    Object.keys(data).forEach((key) => {
        form.set(key, data[key]);
    })
    form.set(window.csrfTokenName, window.csrfTokenValue);
    form.set('action', action)
    return form
}

export default freeform;