<template>
<div>
    <div :class="' fixed inset-0 flex z-50 items-center justify-center ' + (submitting ? 'fixed' : 'hidden')" style="background:#0003; z-index:110;">
        <slot name="submitting"><span class="">Submitting</span></slot>
    </div>
    <div v-if="!submitted" class="text-center">
        <div v-for="section in sections">
            <!--<h3 class="capitalize" :class="{'mt-4': showGiftaid}">{{ section.label }}</h3>-->
            <div v-for="field of section.fields" :key="field.handle">
                <h3 v-if="field.type === 'section-heading'" class="capitalize mt-4" >{{ field.label }}</h3>
                <freeform-payment-field v-if="field.type === 'payment-embed' && field.integration === 'freeform'" :field="field" ref="paymentField"/>
                <div class="text-4xl flex items-center justify-between ml-2" v-if="field.handle === 'amount'">
                            <span class="w-4/12 text-left">
                                <h4 class="normalcase">{{ field.label }}:</h4>
                            </span>
                            <span class="flex items-center justify-center text-gray-500 w-1/12 h-16 mb-2">{{ '$' }}</span>
                            <input style="background-color:rgba(231, 238, 247, 0.3); color: var(--brand-stripe-button-primary-bg)" v-model="field.value" :class="'underline w-7/12 px-4 h-16 mx-2 mb-2 ' + (fieldErrors[field.handle] ? 'border-b border-red-500' : 'border border border-gray-500')"
                                :type="field.type" :id="'form-input-' + field.handle" />
                </div>
                <input v-if="(['text', 'password', 'file', 'email', 'hidden', 'number']).includes(field.type) && field.handle !== 'amount'" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.handle" :placeholder="field.label"
                    class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :class="{'error-border': fieldErrors[field.handle] || submitErrors[field.handle]}"/>
                <textarea v-if="field.type === 'textarea'" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" v-model="field.value" :name="field.handle" :placeholder="field.label" rows="3" class="my-2 border-b py-6 w-full pl-4 text-sm input" :class="{'error-border': fieldErrors[field.handle] || submitErrors[field.handle]}" />
                <select v-if="field.type === 'select'" class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input w-full" :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200': !fieldErrors[field.handle] }" v-model="field.value" :name="field.handle" :placeholder="field.label" v-on:change="ev => field.handle == 'countrySelect' && changeCountry(ev)" >
                    <option v-for="option of field.options" :value="option.value">{{ option.label }}</option>
                </select>
                <div v-if="field.type === 'rich-text'" v-html="field.value" class="mx-3 mt-6 mb-4 text-left" style="text-transform:none; line-height:1rem;">
                </div>
                <div v-if="field.type === 'checkbox'" style="text-transform: none">
                  <div class="self-stretch w-full py-2 pl-4 input mb-2 border-b flex items-center" :class="{'error-border':fieldErrors[field.handle] || submitErrors[field.handle],'border-gray-200': !fieldErrors[field.handle] && !submitErrors[field.handle]}">
                      <div class="text-xs text-left">{{ field.instructions }}</div>
                      <div class="flex mx-4 text-sm">
                        <input v-model="field.value" :type="field.type" :id="'form-input-' + field.handle" :placeholder="field.label" :class="{'error-border mr-2':fieldErrors[field.handle] || submitErrors[field.handle],'border-gray-200 mr-2': !fieldErrors[field.handle] && !submitErrors[field.handle], required:field.required}" />
                        <label>{{ field.label }}</label>
                      </div>
                  </div>
                </div>
                <div v-if="field.type === 'row'" class="flex">
                    <template v-for="(ff, index) of field.fields">
                        <input v-if="(['text', 'password', 'file', 'email', 'hidden', 'textarea']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="ff.label"
                            class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'error-border': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                        <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input flex-1" v-on:change="ev => ff.name == 'countrySelect' && changeCountry(ev)" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="ff.label" :key="ff.name">
                            <option v-for="option of ff.options" :value="option.value">{{ option.label }}</option>
                        </select>
                        <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                        <div v-if="ff.type === 'checkbox'" style="text-transform: none">
                          <div class="self-stretch w-full h-16 py-6 pl-4 input mb-2 border-b flex items-center" :class="{'border-red-500':fieldErrors[ff.name] || submitErrors[ff.name],'border-gray-200': !fieldErrors[ff.name] && !submitErrors[ff.name]}">
                              <div class="text-xs text-left">{{ ff.instructions }}</div>
                              <div class="flex mx-4 text-sm">
                                <input v-model="ff.value" :type="ff.type" :id="'form-input-' + ff.handle" :placeholder="ff.label" :class="{'border-red-500 mr-2':fieldErrors[ff.name] || submitErrors[ff.name],'border-gray-200 mr-2': !fieldErrors[ff.name] && !submitErrors[ff.name], required:ff.required}" />
                                <label>{{ ff.label }}</label>
                              </div>
                          </div>
                        </div>
                    </template>
                </div>
                <div v-if="field.type === 'submit'" class="flex flex-strech">
                    <span v-on:click="submit" class="flex justify-center items-center cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-4">
                        <svg v-if="submitting" id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24.967 24.967" class="mr-4">
                            <g data-name="Group 554" transform="translate(-129.283 0)">
                                <path id="Path_14" data-name="Path 14"
                                    d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
                                    transform="translate(349.182 98.262)" fill="#fff" />
                                <path id="Path_15" data-name="Path 15"
                                    d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
                                    transform="translate(326.478 121.162)" fill="#fff" />
                                <path id="Path_16" data-name="Path 16"
                                    d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
                                    transform="translate(349.573 131.234)" fill="#fff" />
                                <path id="Path_17" data-name="Path 17"
                                    d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
                                    transform="translate(359.45 121.162)" fill="#fff" />
                            </g>
                        </svg>
                        {{submitting ? field.submitting : field.label }}
                    </span>
                </div>
            </div>
        </div>
        <!--<h3 class="capitalize mt-6">Contact</h3>
        <div v-for="field of contactFields" :key="field.handle">
            <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :key="ff.name" :class="{'border-red-500': fieldErrors[field.handle] || submitErrors[field.name]}" />
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option of field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
            <div v-if="field.type === 'row'" class="flex">
                <template v-for="(ff, index) of field.fields">
                    <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="t(ff)"
                        class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'border-red-500': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                    <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="t(ff)" :key="ff.name">
                        <option v-for="option of ff.options" :value="option">{{ ff.mapping[option] }}</option>
                    </select>
                    <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                </template>
            </div>
        </div>

        <h3 class="capitalize mt-6">Address</h3>
        <div v-for="field of addressFields" :key="field.name">
            <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :class="{'border-red-500': fieldErrors[field.name] || submitErrors[field.name]}" />
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option of field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
            <div v-if="field.type === 'row'" class="flex">
                <template v-for="(ff, index) of field.fields">
                    <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="t(ff)"
                        class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'border-red-500': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                    <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input" v-on:change="ev => ff.name == 'fields[country]' && changeCountry(ev)" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="t(ff)" :key="ff.name">
                        <option v-for="option of ff.options" :value="option">{{ ff.mapping[option] }}</option>
                    </select>
                    <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                </template>
            </div>
        </div>

        -->
        <div v-if="showErrors && fieldErrorList.length" class="text-sm text-red-500 mt-2 leading-none">
            <span>{{ text.messages.formErrorMissingFields }}</span>
            <span class="text-xs uppercase font-medium" v-for="field, index in fieldErrorList">{{ t(field) }}<span v-if="index < fieldErrorList.length -1">, </span></span>
        </div>
        <div v-if="submitErrorMessages.length" class="text-sm normal-case text-red-500 mt-2 leading-none">
            <div v-for="error in submitErrorMessages">{{ error }}</div>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal " v-if="submitted && success">
        <div v-html="formMessages.success">
          <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="124.878" height="118.319" viewBox="0 0 124.878 118.319">
            <g id="Group_632" data-name="Group 632" transform="translate(2698.52 -2981.882)">
              <path id="Path_303" data-name="Path 303" d="M112.22,71.349s4.925-19.859,6.386-27.029,7.76-7.517,9.844-6.071,4.63,38.489,4.63,43.239,1.25,10.927-5.393,17.684-16.271,16.256-18.923,18.865-6.928,5.811-6.858,11.161,0,10.078,0,10.078H74.227l2.13-32.111,25.55-15.987Z"
                transform="translate(-2706.814 2960.926)" fill="#3f9de5" />
              <path id="Path_302" data-name="Path 302" d="M98.861,102.515s-7,8.249-23.513,8.249c.112.04.838-7.583.838-7.583s.563-20.066,22.675-19.3c.235,0,13.574-16.97,13.574-16.97s11.185,6.919,2.868,16.97S98.861,102.515,98.861,102.515Z" transform="translate(-2706.66 2964.939)"
                fill="#2f83c3" />
              <path id="Path_304" data-name="Path 304" d="M95.18,71.349S90.255,51.49,88.794,44.32s-7.76-7.517-9.844-6.071-4.63,38.489-4.63,43.239-1.25,10.927,5.393,17.684,16.271,16.256,18.923,18.865,6.928,5.811,6.858,11.161,0,10.078,0,10.078h27.679l-2.13-32.111-25.55-15.987Z"
                transform="translate(-2772.746 2960.926)" fill="#3f9de5" />
              <path id="Path_305" data-name="Path 305" d="M94.866,102.515s7,8.249,23.513,8.249c-.112.04-.838-7.583-.838-7.583s-.563-20.066-22.675-19.3c-.235,0-13.574-16.97-13.574-16.97s-11.185,6.919-2.868,16.97S94.866,102.515,94.866,102.515Z"
                transform="translate(-2759.227 2964.939)" fill="#2f83c3" />
              <ellipse id="Ellipse_304" data-name="Ellipse 304" cx="30.693" cy="30.693" rx="30.693" ry="30.693" transform="translate(-2666.682 2981.882)" fill="#f5f5f5" />
              <path id="Path_306" data-name="Path 306" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" transform="translate(-568 272)" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
          </svg>
          <h3 class="text-center">Thanks for your submission</h3>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal " v-if="submitted && !success">
        <div v-html="formMessages.failed">
            <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="119.455" height="119.455" viewBox="0 0 119.455 119.455">
              <g id="Group_634" data-name="Group 634" transform="translate(2880.717 -2986.847)">
                <circle id="Ellipse_305" data-name="Ellipse 305" cx="59.728" cy="59.728" r="59.728" transform="translate(-2880.717 2986.847)" fill="#f5f5f5" />
                <g id="Group_633" data-name="Group 633" transform="translate(9.682 8.682)">
                  <line id="Line_191" data-name="Line 191" x2="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                  <line id="Line_192" data-name="Line 192" x1="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                </g>
              </g>
            </svg>
            <h3 class="text-center">Something went wrong</h3>
            <p class="text-center">Unfortunately we were unable to receive your submission. Please contact us if the issue continues</p>
            <a class="mt-8 btn" href="mailto:it@empart.org.au">Contact Us</a>
            <a class="mt-8 btn" v-on:click="submitted=false">Try Again</a>
        </div>
    </div>
</div>
</template>

<script>
import EventBus from '../EventBus';
//import {countries, countryMap} from '../utils/countries';

import FreeformPaymentField from './_cform/FreeformPaymentField';

import {fetchRecaptcha} from '../utils/recaptcha';
import submissionHandler from '../utils/submission';
import freeformHandler from '../utils/freeform';

export default {
    props: {
        data: Object,
        text: Object,
        projectId: String,
        segmentId: String,
    },
    components: {
        FreeformPaymentField
    },
    data() {
        return {
            ...this.data, /*fieldMap.reduce((prev, field) => ({
                ...prev,
                [field.name]: field.type === 'row' ? {
                    ...field,
                    fields: field.fields.map((ff) => ({
                        ...ff,
                        value: this.prefill[field.name] || ff.default,
                    }))
                } : {
                    ...field,
                    value: this.prefill[field.name] || field.default,
                }
            }), {}),*/
            //hiddenFields: window.accountSignUpForm.hiddenFields,
            sections: this.data.config.pages.map(page => ({
                fields: page.fields.map(field => ({
                    ...field.data,
                    value: !field.data.value && field.data.type === 'select' ? (field.data.options.filter(opt => opt.selected).length ? field.data.options.filter(opt => opt.selected)[0].value : field.data.options[0].value) : field.data.value
                }))
            })),
            metadata: {
                formHandle: this.data.form.handle,
                action: this.data.action
            },
            submitted: false,
            success: false,
            submitting: false,
            showErrors: false,
            fieldErrors: {},
            submitErrors: {},
            paymentErrors: {},
            submitErrorMessages: [],
            errorTrigger: 0,
        }
    },
    created() {
        //console.log(this.values)
    },
    mounted() {
        //this.$nextTick(() => EventBus.$emit('craft:fetch-state', {code: window.countryCode, callback: this.updateStates}))
        console.log(JSON.stringify(this.config, null, 2))
        this.allFieldsFlattened.filter(f => f.handle === 'paymentEmbed').forEach(f => {
            f.ref = this.$refs.paymentField[0]
        })
        this.allFieldsFlattened.filter(f => f.handle === 'metadata-projectId').forEach(f => {
            f.value = this.projectId
        })
        this.allFieldsFlattened.filter(f => f.handle === 'metadata-segmentId').forEach(f => {
            f.value = this.segmentId
        })
        
        //console.log(data)
    },
    computed: {
        allFieldsFlattened() {
            let result = []
            this.sections.forEach((section) => {
                section.fields.forEach((field) => {
                    if (field.type === 'row') {
                        result = [
                            ...result,
                            ...field.fields
                        ]
                    } else {
                        result.push(field)
                    }
                })
            })

            return result
        },
        fieldErrorList() {
            return Object.keys(this.fieldErrors).map(k => k === 'payment' ? {label: 'Payment'} : this.allFieldsFlattened.find(l => l.handle === k))
        },
        formMessages() {
            let messaging = this.allFieldsFlattened.filter((field) => field.type === 'form-messages');
            return messaging.length ? messaging[0]: {};
        }
    },
    methods: {
        submit() {
            if (this.submitting)
                return;
            this.submitting = true;
            submissionHandler(this.allFieldsFlattened, this.metadata, this.accept, this.reject, this.fail)
        },
        oldSubmit() {
            this.showErrors = true;
            this.paymentErrors = {}
            this.errorTrigger++;
            if (this.submitting)
                return;
            this.submitting = true;
            if (Object.keys(this.fieldErrors).length) {
                console.log("Incomplete fields")
                this.submitting = false;
                return;
            }

            if (this.$refs.paymentField) {
                let attempt = this.$refs.paymentField[0].prepareSubmission(this.collateFieldValues())
                this.paymentErrors = {}
                this.errorTrigger++;
                console.log(attempt)
                if (attempt.success) {
                    fetchRecaptcha()
                    .then(recaptcha => {
                        this.recaptchaToken = recaptcha
                        let data = this.buildData(recaptcha);
                        console.log("About to submit for payment")
                        this.submitting = true;
                        EventBus.$emit('craft:post', {
                            callback: this.postPreflight,
                            action: this.action.replace('submit', 'preflight'),
                            override: window.location,
                            headers: {
                                Accept: 'application/json',
                                'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            data,
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.submitting = false
                        this.errorOccurred = true;
                    })

                } else {
                    this.paymentErrors = attempt.errors
                    this.errorTrigger++;
                    this.submitting = false;
                    return;
                }
                
            } else {
                console.log("Checking recaptcha");
                fetchRecaptcha().then(token => {
                    console.log(token)
                    let data = this.buildData(token);
                    EventBus.$emit('craft:post', {
                        callback: this.postResponse,
                        action: this.action,
                        override: window.location,
                        headers: {
                            Accept: 'application/json',
                            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        data,
                        // data: JSON.stringify({
                        //     ...data
                        // }),
                    })
                })
            }
        },
        changeCountry(ev) {
            return;
            EventBus.$emit('craft:fetch-state', {code: ev.target.value, callback: this.updateStates})
        },
        // updateStates(states) {
        //     const options = states.map(({value, title}) => ({value, label: title}));
        //     if (this.fields.state) {
        //         this.fields['state'].options = options;
        //         this.fields['state'].value = options[0].value;
        //     }
        // },
        async postResponse(resp) {
            this.submitting = false
            try {
                let data = await resp.json();
                if (resp.ok) {

                   if (data.errors) {
                       this.submitErrors = data.errors
                       this.submitErrorMessages = [data.errorMessage]
                       return
                   }
                  this.submitted = true;
                  this.success = true
                  this.submitting = false
                  console.log(resp)
                  this.$emit('success', {
                    resp,
                    values: this.buildData(),
                  })
                } else {
                    this.submitted = true
                    this.success = false
                    
                    this.submitting = false
                    this.$emit('failed', {
                      resp,
                    })
                }
            }
            catch (error) {
                this.$emit('failed', {
                    resp
                })
                this.submitted = true
                this.success = false
                
                this.submitting = false
                console.log(error);
            }
        },
        async postPreflight(resp) {
            try {
                let data = await resp.json();
                if (resp.ok) {

                    if (data.errors) {
                       ;
                       this.submitErrors = data.errors
                       this.submitErrorMessages = [data.errorMessage]
                       return
                    }

                    let payment = await new Promise((resolve, reject) => {
                        if (this.$refs.paymentField[0].currentForm.data.type !== 'single') {
                            let bd = this.$refs.paymentField[0].billingDetails
                            this.$refs.paymentField[0].stripe.createToken(this.$refs.paymentField[0].cardElem, {
                                name: bd.name,
                                address_line1: bd.address.line1,
                                address_line2: bd.address.line2,
                                address_city: bd.address.city,
                                address_state: bd.address.state,
                                address_country: bd.address.country,
                                address_zip: bd.address.postal_code,
                                email: bd.email,
                                phone: bd.phone,
                            })
                            .then(result => {
                                resolve({
                                    error: result.error,
                                    id: result.error ? undefined : result.token.id,
                                })
                            })
                        } else {
                            this.$refs.paymentField[0].stripe.createPaymentMethod({
                                type: 'card',
                                card: this.$refs.paymentField[0].cardElem,
                                billing_details: this.$refs.paymentField[0].billingDetails 
                            })
                            .then(result => {
                                resolve({
                                    error: result.error,
                                    id: result.error ? undefined : result.paymentMethod.id,
                                })
                            })
                        }
                    })

                    console.log("Got a result");
                    if (payment.error) {
                        ;
                        this.errorOccurred = true;
                        this.submitting = false;
                        this.$emit('failed', {
                            resp,
                        })
                        return;
                    } else {
                        console.log("Success!", payment.id);
                        this.submitting = true
                        let parts = this.$refs.paymentField[0].buildData({
                            recaptcha: this.recaptchaToken,
                            payment: payment.id,
                            csrf: {
                                csrfTokenName: window.csrfTokenName,
                                csrfTokenValue: window.csrfTokenValue
                            },
                            fields: this.collateFieldValues()
                        })

                        console.log(parts.data)
                        EventBus.$emit('craft:post', {
                            callback: this.postPayment,
                            action: parts.action,
                            override: window.location,
                            headers: {
                                Accept: 'application/json',
                                'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            data: parts.data,
                            // data: JSON.stringify({
                            //     ...data
                            // }),
                        })
                    }

                } else {
                    this.submitted = true
                    this.success = false
                    
                    this.submitting = false
                    this.$emit('failed', {
                      resp,
                    })
                }
            }
            catch (error) {
                
                this.submitting = false
                this.submitted = true
                this.success = false
                console.log(error);
            }
        },
        fail(error) {
            //dies and resets form
            this.submitting = false
            this.submitted = true
            this.success = false
            console.log(error)
        },
        reject(formErrors, submitErrors) {
            //halts and redisplays form with relevant error
            this.submitting = false
            this.showErrors = true
            this.fieldErrors = formErrors
            this.submitErrorMessages = submitErrors
        },
        accept(resp) {
            //display success, mandatory if payment successful
            this.metadata.submissionId = resp.submissionId
            console.log(resp)

            if (this.allFieldsFlattened.some(f => f.handle == 'paymentEmbed' && (!f.status || f.status == 'incomplete'))) {
                console.log("Trigger payment")
                freeformHandler(this.allFieldsFlattened, this.metadata, this.acceptPayment, this.reject, this.fail)
            } else {
                this.submitting = false
                this.success = true
                this.submitted = true
            }
        },
        acceptPayment(resp) {
            console.log(resp)
            this.metadata.paid = true
            submissionHandler(this.allFieldsFlattened, this.metadata, this.accept, this.reject, this.fail)
        },
        async postPayment(resp) {
            console.log("Post Payment Landed")
            try {
                let data = await resp.json();
                console.log("Data loaded from JSON")
                if (resp.ok) {

                    console.log("Payment response is OK")
                    if (!data.success) {
                        ;
                        this.submitting = false
                        if (data.errors.length) {
                            console.log("Payment rejected")
                            this.submitErrors = data.errors
                            this.submitErrorMessages = data.errors
                        } else if (data.formErrors.length) {
                            console.log("Payment failed")
                            this.submitErrors = data.formErrors
                            this.submitErrorMessages = data.formErrors
                        }
                        this.$emit('failed', {
                            resp,
                        })
                        return
                    }
                    if (!data.finished) {
                        console.log("")
                    }
                    
                    console.log("Freeform response", data)
                    let rebuild = {
                        ...this.buildData(this.recaptchaToken),
                        'paymentEmbed': JSON.stringify({
                            type: 'freeform',
                            submissionId: data.submissionId,
                        })
                    };
                    this.submitting = true
                    EventBus.$emit('craft:post', {
                        callback: this.postResponse,
                        action: this.action,
                        override: window.location,
                        headers: {
                            Accept: 'application/json',
                            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        data: rebuild,
                        // data: JSON.stringify({
                        //     ...data
                        // }),
                    })
                } else {
                    this.submitted = true
                    this.success = false
                    
                    this.submitting = false
                    this.$emit('failed', {
                      resp,
                    })
                }
            }
            catch (error) {
                this.$emit('failed', {
                    resp
                })
                
                this.submitting = false
                this.submitted = true
                this.success = false
                console.log(error);
            }
            console.log("All Done with Payment Response")
        },
        t(field) {
            return field.label
        },
        collateFieldValues(skipIfPayment=true) {
            let data = {}
            this.allFieldsFlattened.forEach(({handle, value}) => {
                if (handle)
                    data[handle] = typeof(value) !== 'undefined' ? value : ''
            })

            return data;
        },
        buildData(recaptchaToken, paymentBundle) {
            let data = this.collateFieldValues(false);

            //data['formHash'] = this.hash
            data[window.csrfTokenName] = window.csrfTokenValue;
            if (paymentBundle)
                data['paymentEmbed'] = JSON.stringify(paymentBundle)
            data['c-form'] = this.form.handle
            data['g-recaptcha-response'] = recaptchaToken
            return data
        },
        buildForm(data, action) {
          let form = new FormData();
          Object.keys(data).forEach((key) => {
              form.set(key, data[key]);
          })
          form.set(this.csrfName, this.csrfToken);
          form.set('action', action)
          return form
      },
    }
}
</script>
