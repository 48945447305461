<template>
    <div class="normal-case my-2">
        <template v-if="loading">
            <div>Loading...</div>
        </template>
        <template v-else>
            <div class="mt-4 mb-2 flex w-full text-sm">
                <div v-for="form of forms" :key="form.id"
                    class="flex flex-1 items-center font-bold text-base h-6 px-1 cursor-pointer"
                    v-on:click="setForm(form.id)">
                    <input type="checkbox" class="text-xl" :checked="form.id === freeformId" :value="form.id"
                        style="pointer-events:none;" />
                    <span class="ml-4">{{ form.name }}</span>
                </div>
            </div>
            <div v-if="currentForm">
                <div v-for="row of currentForm.data.layout[0]" class="flex flex-col">
                    <template v-for="field of getCurrentFieldsFromColumn(row)">
                        <div class="text-4xl flex items-center justify-between ml-2" v-if="field.handle === 'amount'">
                            <span class="w-4/12 text-left">
                                <h4>{{ field.label }}:</h4>
                            </span>
                            <span class="flex items-center justify-center text-gray-500 w-1/12 h-16 mb-2">{{ '$' }}</span>
                            <input style="background-color:rgba(231, 238, 247, 0.3); color: var(--brand-stripe-button-primary-bg)" v-model="field.value" :class="'underline w-7/12 px-4 h-16 mx-2 mb-2 ' + (fieldErrors[field.handle] ? 'border-b border-red-500' : 'border border border-gray-500')"
                                :type="field.type" :id="'form-input-' + field.handle" />
                        </div>
                        <input v-model="field.value"
                            v-if="!['cc_details', 'submit', 'checkbox', 'row', 'select', 'rich_text', 'placedate'].includes(field.type) && field.handle !== 'amount'"
                            class="self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 border-b"
                            :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200': !fieldErrors[field.handle], required: field.required }"
                            :type="field.type" :id="'form-input-' + field.handle" :placeholder="field.label" />
                        <div v-if="field.type === 'cc_details'">
                            <div ref="cardinput">CC Details</div>
                        </div>
                        <input v-if="field.type === 'placedate'" :placeholder="field.label"
                            class="self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 border-b"
                            :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200': !fieldErrors[field.handle], required: field.required }"
                            type="text" v-on:focus="field.type = 'date'" />
                        <select v-if="field.type === 'select'"
                            class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input w-full" v-model="field.value"
                            :name="field.name" :placeholder="field.label">
                            <option v-for="option of field.options" :value="option.value">{{ option.label }}</option>
                        </select>
                        <div v-if="field.type === 'checkbox' && field.handle !== 'giftAidConsent'">
                            <div class="self-stretch w-full h-16 py-6 pl-4 input mb-2 border-b flex items-center"
                                :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200': !fieldErrors[field.handle] }">
                                <div class="text-xs">{{ field.instructions }}</div>
                                <div class="flex mx-4 text-sm">
                                    <input v-model="field.value" :type="field.type" :id="'form-input-' + field.handle"
                                        :placeholder="field.label"
                                        :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200 mr-2': !fieldErrors[field.handle], required: field.required }" />
                                    <label>{{ field.label }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="field.type === 'rich_text'">
                            <div class="text-xs self-stretch w-full py-2 pl-2 mb-2 flex"
                                :class="{ 'error-border': fieldErrors[field.handle], 'border-gray-200': !fieldErrors[field.handle] }">
                                <div class="text-xs" v-html="field.value"></div>
                            </div>
                        </div>
                        <div v-if="field.type === 'row'" class="flex">
                            <template v-for="(ff, index) of field.fields">
                                <input v-model="ff.value"
                                    v-if="!['cc_details', 'submit', 'checkbox', 'select', 'rich_text', 'placedate'].includes(ff.type) && ff.handle !== 'amount'"
                                    class="border-b py-6 pl-4 h-16 mb-2 text-sm input flex-1"
                                    :class="{ 'error-border': fieldErrors[ff.handle], 'border-gray-200': !fieldErrors[ff.handle], required: ff.required }"
                                    :type="showHiddenFields && ff.type == 'hidden' ? 'text' : ff.type"
                                    :id="'form-input-' + ff.handle" :placeholder="ff.label" />
                                <div v-if="ff.type === 'cc_details'">
                                    <div ref="cardinput">CC Details</div>
                                </div>
                                <input v-if="ff.type === 'placedate'" :placeholder="ff.label"
                                    class="border-b py-6 pl-4 h-16 mb-2 text-sm input flex-1"
                                    :class="{ 'error-border': fieldErrors[ff.handle], 'border-gray-200': !fieldErrors[ff.handle], required: ff.required }"
                                    type="text" v-on:focus="ff.type = 'date'" />
                                <select v-if="ff.type === 'select'"
                                    class="w-24  h-16 py-6 pl-4 text-sm input mb-2 border-b flex-1" v-model="ff.value"
                                    :name="ff.name" :placeholder="ff.label"
                                    v-on:change="ev => ff.name == 'countrySelect' && changeCountry(ev)">
                                    <option v-for="option of ff.options" :value="option.value">{{ option.label }}</option>
                                </select>
                                <span class="w-4" v-if="index < field.fields.length - 1" :key="ff.name + '-space'" />
                                <div v-if="ff.type === 'checkbox' && ff.handle !== 'giftAidConsent'">
                                    <div class="self-stretch w-24 h-16 py-6 pl-4 input mb-2 border-b flex items-center"
                                        :class="{ 'error-border': fieldErrors[ff.handle], 'border-gray-200': !fieldErrors[ff.handle] }">
                                        <div class="text-xs">{{ ff.instructions }}</div>
                                        <div class="flex mx-4 text-sm">
                                            <input v-model="ff.value" :type="ff.type" :id="'form-input-' + ff.handle"
                                                :placeholder="ff.label"
                                                :class="{ 'error-border': fieldErrors[ff.handle], 'border-gray-200 mr-2': !fieldErrors[ff.handle], required: ff.required }" />
                                            <label>{{ ff.label }}</label>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        field: Object,
        text: Object,
    },
    data() {
        return {
            freeformId: 0,
            loading: true,
            value: {},
            forms: [],
            cardElem: undefined,
            elements: undefined,
            cardValid: false,
            showErrors: false,
            triggerData: 0,
            pendingData: {},
            stripe: undefined,
            billingDetails: {
                address: {},
            },
        }
    },
    computed: {
        currentForm() {
            if (this.forms.length && this.forms.find(form => form.id == this.freeformId)) {
                return this.forms.find(f => f.id == this.freeformId)
            }
        },

        fieldErrors() {
            this.triggerData;
            let errors = {}
            if (!this.showErrors) {
                return errors;
            }
            
            this.flattenFields.forEach(field => {
                if (this.currentForm.mapping[field.handle] && this.pendingData) {
                    field.value = this.pendingData[this.currentForm.mapping[field.handle]]
                }

                if (field.type === 'cc_details' && !this.cardValid) {
                    console.log("Card is invalid");
                    errors[field.handle] = true;
                }
                console.log(field.handle)
                if (field.handle && field.required && field.type !== 'cc_details' && !field.value) {
                    errors[field.handle] = true;
                } else if (field.handle && field.required && field.type === 'email' && !field.value.length) {
                    errors[field.handle] = true;
                } else {
                    console.log(field.handle, field.required, field.type, field.value);
                }
            })

            return errors
        },

        flattenFields() {
            let flat = []
            
            this.currentForm.data.layout[0].forEach(row => {
                const fields = row.columns.map(c => this.currentForm.data.fields[c]).filter(f => f)
                if (fields) {
                    fields.forEach(f => flat.push(f))
                }
            })

            return flat
        }
    },
    async mounted() {
        
        for (let form of this.field.freeform.forms) {
            if (form.handle) {
                let def = await fetch(`/platonic/freeform/${form.handle}`)
                    .then(r => r.json())
                console.log(JSON.stringify(def, null, 2))
                this.forms.push({
                    ...form,
                    data: def
                })
            }
        }
        this.loading = false
        this.freeformId = this.forms.length ? this.forms[0].id : 0

        this.mountStripe()
    },
    methods: {
        setForm(state) {
            this.freeformId = state
            this.mountStripe()
        },

        mountStripe() {
            this.resetAndRemoveCard()
            if (this.forms.length) {
                console.log("Loading Stripe")
                this.stripe = Stripe(this.currentForm.data.stripe, { locale: window.stripeLocale })
                this.elements = this.stripe.elements()
                this.$nextTick(this.createCardElem);
            }
        },

        getCurrentFieldsFromColumn(row) {
            if (this.currentForm) {
                //console.log(JSON.stringify(row, null, 2))
                //console.log(JSON.stringify(row.columns.map(c => this.currentForm.data.fields[c]), null, 2))
                const columns = row.columns.map(c => this.currentForm.data.fields[c]).filter(f => f && !this.currentForm.mapping[f.handle])
                console.log(JSON.stringify(columns, null, 2), JSON.stringify(this.currentForm.mapping, null, 2))
                return columns
            }
            return []
        },

        prepareBillingDetails(data) {
            const stripeMap = this.field.stripe.billingMap
            console.log("Check billing", this.field.stripe)
            if (!this.field.stripe.feedBilling) {
                console.log("No billing feed, skipping")
                return
            }
            Object.keys(stripeMap).forEach(k => {
                if (k === 'address') {
                    this.billingDetails.address = {}
                    console.log(stripeMap.address)
                    Object.keys(stripeMap.address).forEach(l => {
                        console.log(l, stripeMap.address[l])
                        if (stripeMap.address[l]) {
                            this.billingDetails.address[l] = data[stripeMap.address[l]]
                        }
                    })
                }
                else if (stripeMap[k]) {
                    this.billingDetails[k] = data[stripeMap[k]]
                }
            })

            

            console.log("Billing feed", JSON.stringify(this.billingDetails, null, 2))
        },

        prepareSubmission(data) {
            console.log("Preparing submission:", data)
            this.showErrors = true;
            this.triggerData++
            this.pendingData = data;

            this.prepareBillingDetails(data)

            if (this.loading) {
                return {
                    success: false,
                    error: "Form still loading",
                    fieldErrors: this.fieldErrors
                }
            }
            
            let subFields = this.flattenFields.concat(this.currentForm.data.hidden).map(f => this.currentForm.mapping[f.handle] ? {...f, value:data[this.currentForm.mapping[f.handle]]} : !f.type && !f.value && data[f.handle] ? {...f, value:data[f.handle]} : f)

            if (Object.keys(this.fieldErrors).length) {
                return {
                    success: false,
                    errors: this.fieldErrors,
                }
            }

            console.log(JSON.stringify(subFields, null, 2))
            let sendData = {

                ...subFields.filter(f => f && f.handle && f !== 'undefined').reduce((d, f) => {d[f.handle] = f.value; return d}, {})
            }

            console.log("send data", JSON.stringify(sendData, null, 2))
            return {
                success: true,
                data: sendData,
            }
        },

        buildData(context) {
            console.log(this.prepareSubmission(context.fields).data)
            return {
                data: {
                    ...this.prepareSubmission(context.fields).data,
                    'g-recaptcha-response': context.recaptcha,
                    'payment': context.payment,
                    formHash: this.currentForm.data.hash,
                    'freeform-action': 'submit',
                    'freeform_payload': this.currentForm.data.form.freeform_payload,
                    [context.csrf.csrfTokenName]: context.csrf.csrfTokenValue,
                },
                form: this.currentForm.handle,
                action: this.currentForm.data.action
            }
        },

        createCardElem() {
            console.log("Do Something!")
            this.cardElem = this.elements.create('payment', {
                classes: {
                    base: "border-b border-gray-200 self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 text-gray-500 text-xl",
                    invalid: 'error-border',
                },
                hidePostalCode: true,
            });
            this.cardElem.on('change', this.cardChange);
            console.log(this.$refs)
            if (this.$refs.cardinput) {
                console.log("This is stupid")
                console.log(this.$refs.cardinput)
                this.cardElem.mount(this.$refs.cardinput[0]);
            }
        },

        cardChange(payload) {
            if (payload.complete) {
                console.log("Card valid");
                this.cardValid = true;
            } else {
                this.cardValid = false;
                this.cardSource = undefined;
                console.log("Card invalid");
            }
        },

        resetAndRemoveCard() {
            this.donation = false;
            this.processing = false;
            this.success = false;
            this.cardValid = false;
            this.cardSource = undefined;
            if (this.cardElem)
                this.cardElem.destroy();
        },
    }
}
</script>