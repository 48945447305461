
import {fetchRecaptcha} from './recaptcha';

const submission = async (fields, metadata, accept, reject, fail, final = () => {}) => {
    try {
        let errors = await fieldErrors(fields);
        if (Object.keys(errors).length) {
            reject(errors)
            return
        }

        // Fetch recaptcha token
        if (!metadata.recaptcha) {
            metadata.recaptcha = await fetchRecaptcha();
            console.log(metadata.recaptcha)
        }

        let data = {}
        fields.forEach(({handle, value}) => {
            if (handle)
                data[handle] = typeof(value) !== 'undefined' ? value : ''
        })

        //data['formHash'] = this.hash
        data[window.csrfTokenName] = window.csrfTokenValue;
        data['c-form'] = metadata.formHandle
        if (metadata.submissionId)
            data['submissionId'] = metadata.submissionId
        data['g-recaptcha-response'] = metadata.recaptcha

        let form = buildForm(data, metadata.action)

        let r = await fetch(window.location, {
            method: 'POST',
            body: form,
            headers: {
                Accept: 'application/json',
                'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                'X-Requested-With': 'XMLHttpRequest'
            },
        }).then(r => r.json())
        .catch(er => {
            fail(er)
        })

        if (r.success) {
            accept(r)
            return
        } else {
            reject(r.errors)
            return
        }

    } catch (error) {
        if (metadata.paid) {
            accept({submissionId: metadata.submissionId})
        } else {
            fail(error)
        }
        
    } finally {
        final()
    }
}

const fieldErrors = async (fields) => {
    let errors = {}
    let processPayment = false;

    fields.forEach(({
        handle,
        value,
        type,
        required,
        fields,
        status
    }) => {
        if (!handle) {
            return
        }
        if (handle === 'paymentEmbed' && (!status || status == 'incomplete')) {
            processPayment = true
        }
        if (type === 'row') {
            fields.map((ff) => {
                if (!ff.value && ff.required) {
                    errors[ff.handle] = "Field is empty"
                }
            })
        } else if (!value && required) {
            errors[handle] = "Field is empty"
        }
    })

    if (!Object.keys(errors).length && processPayment) {
        let data = {}
        fields.forEach(({handle, value}) => {
            if (handle)
                data[handle] = typeof(value) !== 'undefined' ? value : ''
        })
        fields.filter(f => f.handle == 'paymentEmbed').forEach(f => {
            let {
                handle,
                ref
            } = f;
            let attempt = ref.prepareSubmission(data)
            if (!attempt.success) {
                for (let key in attempt.errors) {
                    errors[key] = attempt.errors[key]
                }
            } else {
                //establishes the status var.
                let {formHash, freeform_payload, payment} = attempt.data;
                let freeform_action = attempt.data['freeform-action']
                f.status = 'incomplete'
                f.value = JSON.stringify({
                    action: freeform_action,
                    type: 'freeform',
                    form: attempt.form,
                    data: {
                        formHash,
                        freeform_payload,
                        payment,
                        'freeform-action': freeform_action
                    },
                    status: 'incomplete'
                })
            }
        })
    }

    return errors;
}

const buildForm = (data, action) => {
    let form = new FormData();
    Object.keys(data).forEach((key) => {
        form.set(key, data[key]);
    })
    form.set(window.csrfTokenName, window.csrfTokenValue);
    form.set('action', action)
    return form
}

export default submission