<template>
<div>
  <div ref="formdata">
    <slot></slot>
  </div>

  <!--<h4 v-if="!success && !processing" class="text-center font-bold mb-2">Donate to {{ title }}</h4>
        <h4 v-if="success" class="text-center mb-2">Thanks for your donation!</h4>
        <h3 v-if="!success && processing" class="text-center mb-2">Processing...</h3>-->
  <div v-for="field in sections[0].fields" :class="'flex flex-col'">
    <input v-model="field.value" v-if="!['cc_details', 'submit', 'checkbox', 'row', 'select', 'rich_text', 'placedate'].includes(field.type) && field.handle !== 'amount'" class="self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 border-b" :class="{'error-border':fieldErrors[field.handle],'border-gray-200': !fieldErrors[field.handle], required:field.required}"
      :type="showHiddenFields && field.type == 'hidden' ? 'text' : field.type" :id="'form-input-' + field.handle" :placeholder="field.label"/>
    <div v-if="field.type === 'cc_details'">
      <div ref="cardinput">CC Details</div>
    </div>
    <input v-if="field.type === 'placedate'" :placeholder="field.label"  class="self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 border-b" :class="{'error-border':fieldErrors[field.handle],'border-gray-200': !fieldErrors[field.handle], required:field.required}" type="text" v-on:focus="field.type='date'"/>
    <select v-if="field.type === 'select'" class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input w-full"  v-model="field.value" :name="field.name" :placeholder="field.label" v-on:change="ev => field.name == 'countrySelect' && changeCountry(ev)" >
        <option v-for="option of field.options" :value="option.value">{{ option.label }}</option>
    </select>
    <div v-if="field.type === 'checkbox' && field.handle !== 'giftAidConsent'">
      <div class="self-stretch w-full h-16 py-6 pl-4 input mb-2 border-b flex items-center" :class="{'error-border':fieldErrors[field.handle],'border-gray-200': !fieldErrors[field.handle]}">
          <div class="text-xs">{{ field.instructions }}</div>
          <div class="flex mx-4 text-sm">
            <input v-model="field.value" :type="field.type" :id="'form-input-' + field.handle" :placeholder="field.label" :class="{'error-border':fieldErrors[field.handle],'border-gray-200 mr-2': !fieldErrors[field.handle], required:field.required}" />
            <label>{{ field.label }}</label>
          </div>
      </div>
    </div>
    <div v-if="field.type === 'rich_text'">
      <div class="text-xs self-stretch w-full py-2 pl-2 mb-2 flex" :class="{'error-border':fieldErrors[field.handle],'border-gray-200': !fieldErrors[field.handle]}">
          <div class="text-xs" v-html="field.value"></div>
      </div>
    </div>
    <div v-if="field.type === 'row'" class="flex">
        <template v-for="(ff, index) of field.fields">
            <input v-model="ff.value" v-if="!['cc_details', 'submit', 'checkbox', 'select', 'rich_text', 'placedate'].includes(ff.type) && ff.handle !== 'amount'" class="border-b py-6 pl-4 h-16 mb-2 text-sm input flex-1" :class="{'error-border':fieldErrors[ff.handle],'border-gray-200': !fieldErrors[ff.handle], required:ff.required}"
              :type="showHiddenFields && ff.type == 'hidden' ? 'text' : ff.type" :id="'form-input-' + ff.handle" :placeholder="ff.label"/>
            <div v-if="ff.type === 'cc_details'">
              <div ref="cardinput">CC Details</div>
            </div>
            <input v-if="ff.type === 'placedate'" :placeholder="ff.label"  class="border-b py-6 pl-4 h-16 mb-2 text-sm input flex-1" :class="{'error-border':fieldErrors[ff.handle],'border-gray-200': !fieldErrors[ff.handle], required:ff.required}" type="text" v-on:focus="ff.type='date'"/>
            <select v-if="ff.type === 'select'" class="w-24  h-16 py-6 pl-4 text-sm input mb-2 border-b flex-1"  v-model="ff.value" :name="ff.name" :placeholder="ff.label" v-on:change="ev => ff.name == 'countrySelect' && changeCountry(ev)" >
                <option v-for="option of ff.options" :value="option.value">{{ option.label }}</option>
            </select>
            <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
            <div v-if="ff.type === 'checkbox' && ff.handle !== 'giftAidConsent'">
              <div class="self-stretch w-24 h-16 py-6 pl-4 input mb-2 border-b flex items-center" :class="{'error-border':fieldErrors[ff.handle],'border-gray-200': !fieldErrors[ff.handle]}">
                  <div class="text-xs">{{ ff.instructions }}</div>
                  <div class="flex mx-4 text-sm">
                    <input v-model="ff.value" :type="ff.type" :id="'form-input-' + ff.handle" :placeholder="ff.label" :class="{'error-border':fieldErrors[ff.handle],'border-gray-200 mr-2': !fieldErrors[ff.handle], required:ff.required}" />
                    <label>{{ ff.label }}</label>
                  </div>
              </div>
            </div>
        </template>
    </div>
    <gift-aid v-if="field.handle === 'giftAidConsent'" v-model="field.value" :content="giftaid" :prefill="form" :user="user"/>

    <div class="text-4xl flex items-center justify-between ml-2" v-if="field.handle === 'amount'">
      <span class="w-4/12 text-left">
        <h4>{{ form.amount.label }}:</h4>
    </span><span class="flex items-center justify-center text-gray-500 w-1/12 h-16 mb-2">{{ symbol }}</span><input style="background-color:rgba(231, 238, 247, 0.3); color: var(--brand-stripe-button-primary-bg)" v-model="field.value" :class="'underline w-7/12 px-4 h-16 mx-2 mb-2 ' + (fieldErrors[field.handle] ? 'border-b border-red-500' : 'border border border-gray-500')"
        :type="field.type" :id="'form-input-' + field.handle" :placeholder="field.label" />
    </div>
  </div>

  <div v-if="showConsent && false">
      <span class="self-stretch w-full h-16 py-6 pl-4 input mb-2 border-b flex items-center">
          Here is a bunch of consent
      </span>
  </div>
  <div v-if="showDeposit && form.amount && form.amount.value" class="mb-2 self-stretch flex normal-case">
    When you register, you will be charged a deposit of {{ symbol }}{{ form.amount.value }}.
  </div>
  <div class="mb-2 self-stretch flex flex-col">
    <span v-on:click="submit" class="flex justify-center items-center cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-4" style="background: var(--brand-stripe-button-primary-bg); color: var(--brand-stripe-button-primary-text); border-color: var(--brand-stripe-button-primary-bg);">
      <svg v-if="submitting" id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 32 32" class="mr-4">
        <path d="M32 16c-0.040-2.089-0.493-4.172-1.331-6.077-0.834-1.906-2.046-3.633-3.533-5.060-1.486-1.428-3.248-2.557-5.156-3.302-1.906-0.748-3.956-1.105-5.981-1.061-2.025 0.040-4.042 0.48-5.885 1.292-1.845 0.809-3.517 1.983-4.898 3.424s-2.474 3.147-3.193 4.994c-0.722 1.846-1.067 3.829-1.023 5.79 0.040 1.961 0.468 3.911 1.254 5.694 0.784 1.784 1.921 3.401 3.316 4.736 1.394 1.336 3.046 2.391 4.832 3.085 1.785 0.697 3.701 1.028 5.598 0.985 1.897-0.040 3.78-0.455 5.502-1.216 1.723-0.759 3.285-1.859 4.574-3.208 1.29-1.348 2.308-2.945 2.977-4.67 0.407-1.046 0.684-2.137 0.829-3.244 0.039 0.002 0.078 0.004 0.118 0.004 1.105 0 2-0.895 2-2 0-0.056-0.003-0.112-0.007-0.167h0.007zM28.822 21.311c-0.733 1.663-1.796 3.169-3.099 4.412s-2.844 2.225-4.508 2.868c-1.663 0.646-3.447 0.952-5.215 0.909-1.769-0.041-3.519-0.429-5.119-1.14-1.602-0.708-3.053-1.734-4.25-2.991s-2.141-2.743-2.76-4.346c-0.621-1.603-0.913-3.319-0.871-5.024 0.041-1.705 0.417-3.388 1.102-4.928 0.683-1.541 1.672-2.937 2.883-4.088s2.642-2.058 4.184-2.652c1.542-0.596 3.192-0.875 4.832-0.833 1.641 0.041 3.257 0.404 4.736 1.064 1.48 0.658 2.82 1.609 3.926 2.774s1.975 2.54 2.543 4.021c0.57 1.481 0.837 3.064 0.794 4.641h0.007c-0.005 0.055-0.007 0.11-0.007 0.167 0 1.032 0.781 1.88 1.784 1.988-0.195 1.088-0.517 2.151-0.962 3.156z" fill="#fff"></path>
        <!--<g data-name="Group 554" transform="translate(-129.283 0)">
          <path id="Path_14" data-name="Path 14" d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
            transform="translate(349.182 98.262)" fill="#fff" />
          <path id="Path_15" data-name="Path 15" d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
            transform="translate(326.478 121.162)" fill="#fff" />
          <path id="Path_16" data-name="Path 16" d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
            transform="translate(349.573 131.234)" fill="#fff" />
          <path id="Path_17" data-name="Path 17" d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
            transform="translate(359.45 121.162)" fill="#fff" />
        </g>-->
      </svg>
      {{submitting ? "Processing" : (submitText || "Submit")}}
    </span>
    <div class="text-sm text-red-500 mt-2">
        <div v-if="showErrors && fieldErrorList.length">
          <span>Missing required fields: </span>
          <span class="text-xs uppercase font-medium" v-for="field, index in fieldErrorList" v-if="fieldErrors[field.handle]">{{ field.handle == 'payment' ? 'Payment' : field.label }}<span v-if="index < fieldErrorList.length -1">, </span></span>
      </div>
      <div v-if="submissionErrors.length">
          <span class="text-xs uppercase font-medium" v-for="error, index in submissionErrors" >{{ error }}<span v-if="index < submissionErrors.length -1">, </span></span>
      </div>
    </div>
  </div>
  <!--<div v-if="success" class="mx-2 mb-2 self-stretch flex">
            <span v-on:click="hideDonation" class="cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-1">Close</span>
        </div>-->
</div>
</template>

<script>
import Vue from 'vue';
import EventBus from '../EventBus'
import GiftAid from './GiftAid'

import {fetchRecaptcha} from '../utils/recaptcha';

export default {
  props: {
    text: Object,
    title: String,
    project: String,
    fields: Array,
    paymentForm: Object,
    reuse: Boolean,
    handle: String,
    addendumFields: Array,
    initialAmount: Number,
    hiddenFields: Array,
    showDeposit: Boolean,
    submitText: String,
    stripeKey: String,
    showGiftaid: Boolean,
    giftaid: Object,
    showConsent: Boolean,
    consent: Array,
    user: Object,
    showUserOptout: Boolean,
    addRecaptcha: Boolean,
    showHiddenFields: Boolean,
  },
  data() {
    let stripe = Stripe(this.paymentForm.stripe, {locale: window.stripeLocale})
    return {
      status: "initial",
      showErrors: false,
      cardValid: false,
      cardSource: undefined,
      submitting: false,
      symbol: window.currencySymbol,
      extraFields: {},
      giftAidDetails: {},
      giftaidSubmission: '',
      /*subscribe: false,
      processing: false,
      success: false,
      amount: 50,*/
      form: {},
      submissionErrors: [],
      mapping: this.fields.map((field) => field.handle),
      donation: false,
      stripe,
      elements: stripe.elements(),
      recaptchaToken: '',
    }
  },
  mounted() {
    if (this.hiddenFields) {
      //console.log("Processing hidden fields from args")
      this.hiddenFields.forEach(({
        name,
        value
      }) => {
        this.extraFields[name] = value;
      })
    } else if (this.$refs.formdata) {
      let specialInputs = this.$refs.formdata.getElementsByTagName('input')
      //console.log("Currently ", specialInputs.length, "inputs")
      for (var i = 0; i < specialInputs.length; i++) {
        var elem = specialInputs.item(i)

        if (this.showHiddenFields && elem.getAttribute("id") && !this.fields.some(({name}) => name == elem.getAttribute("name"))) {
          //console.log(elem.getAttribute("name"), elem.getAttribute("value"))
          this.fields.push({
              type: "text",
              name: elem.getAttribute("name"),
              handle: elem.getAttribute("name"),
              label: elem.getAttribute("name"),
              value: elem.getAttribute("value"),
              required: (['projectID']).includes(elem.getAttribute("name"))
          })
        } else if (elem.getAttribute("value")) {
          //console.log(elem.getAttribute("name"), elem.getAttribute("value"))
          this.extraFields[elem.getAttribute("name")] = elem.getAttribute("value")
        }

        this.mapping = this.fields.map((field) => field.handle)
      }
    }

    if (this.addendumFields) {
        this.addendumFields.forEach(({name, value}) => {this.extraFields[name] = value})
    }
    //console.log("Capturing recaptcha:", this.addRecaptcha)
    this.form = this.fields.reduce((prev, field) => {
      if (!field || !field.handle)
        return prev;
      //console.log(field.handle)
      field['label'] = field.type == 'cc_details' ? 'Payment Details' : field.label
      if (!this.extraFields[field.name] && !this.extraFields[field.handle])
        prev[field.handle] = field;
      return prev;
    }, {})

    let amount = this.fields.filter(field => field.handle == 'amount')[0].value
    this.sections[0].fields.filter(field => field.handle == 'amount').forEach(field => { field.value = amount })

    this.$nextTick(this.createCardElem);
  },
  computed: {
    /*donateButtonCls() {
        if (this.footer) {
          return "cursor-pointer rounded-sm flex-1 bg-white uppercase text-sm brand-text border-2 border-white text-white text-center px-2 h-12 flex justify-center items-center";
        } else {
          return "cursor-pointer rounded-sm flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 h-12 flex justify-center items-center";
        }
    },*/
    mappedFields() {
        /*let fields = this.paymentFormwindow.accountSignUpForm.layout[0].reduce((prev, row) => {
            row.columns.forEach(hash => {
                if (window.accountSignUpForm.fields[hash])
                    prev[window.accountSignUpForm.fields[hash].handle] = window.accountSignUpForm.fields[hash]
            })
            return prev
        }, {}
    )*/
        //console.log(this.paymentForm)
      return Object.keys(this.form).length ? this.mapping.filter((handle) => handle && this.extraFields[handle] === undefined).map((handle) => this.form[handle]) : [];
    },
    sections() {
        let current = {
            fields: [],
        }
        let output = [current];
        let section = ""

        for (let row of this.paymentForm.layout[0]) {
            if (!this.paymentForm.fields[row.columns[0]])
                continue;
            if (this.paymentForm.fields[row.columns[0]].type !== 'rich_text' && !this.form[this.paymentForm.fields[row.columns[0]].handle])
                continue;

            if (row.columns.length > 1) {
                row.columns.forEach((hash, index) => {this.form[this.paymentForm.fields[hash].handle] = this.paymentForm.fields[hash]})
                current.fields.push({
                    type: 'row',
                    fields: row.columns.map((hash) => this.form[this.paymentForm.fields[hash].handle])
                })
            } else {
                this.form[this.paymentForm.fields[row.columns[0]].handle] = this.paymentForm.fields[row.columns[0]];
                current.fields.push(this.form[this.paymentForm.fields[row.columns[0]].handle])
            }
        }

        if (this.showHiddenFields) {
            this.fields.filter(({type}) => type === 'hidden').forEach(field => {
                current.fields.push(field)
            })
        }

        return output
    },
    fieldErrors() {
      let out = {};
      if (!this.showErrors) {
        return out;
      }

      console.log("SHOW ERRORS")
      for (let field of this.mappedFields) {
        if (field.type === 'cc_details' && !this.cardValid) {
          console.log("Card is invalid");
          out[field.handle] = true;
        }
        console.log(field.handle)
        if (field.handle && field.required && field.type !== 'cc_details' && !field.value) {
          out[field.handle] = true;
        } else if (field.handle && field.required && field.type === 'email' && !field.value.length) {
          out[field.handle] = true;
        } else {
          console.log(field.handle, field.required, field.type, field.value);
        }
      }

      return out;
    },
    fieldErrorList() {
      return this.mappedFields.filter((field) => this.fieldErrors[field.handle])
    },
    cardSourceDetails() {
      return {
        type: 'card',
        usage: this.reuse ? 'reusable' : 'single_use',
        currency: this.extraFields['currency'] ? this.extraFields['currency'].toLowerCase() : this.form['currency'].value,
        amount: this.form['amount'].value * 100,
        owner: {
          name: this.form['firstName'].value + ' ' + this.form['lastName'].value,
          email: this.form['email'].value,
        }
      }
    }
  },
  beforeDestroy() {
    this.resetAndRemoveCard()
  },
  methods: {
    /*
    toggleSubscribe() {
        this.subscribe = !this.subscribe;
    },
    */
    createCardElem() {
      this.cardElem = this.elements.create('card', {
        classes: {
          base: "border-b border-gray-200 self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 text-gray-500 text-xl",
          invalid: 'error-border',
        },
        hidePostalCode: true,
      });
      this.cardElem.on('change', this.cardChange);
      if (this.$refs.cardinput) {
        //console.log(this.$refs.cardinput)
        this.cardElem.mount(this.$refs.cardinput[0]);
      }
    },

    changeCountry(ev) {

    },

    cardChange(payload) {
      if (payload.complete) {
        console.log("Card valid");
        this.cardValid = true;
      } else {
        this.cardValid = false;
        this.cardSource = undefined;
        console.log("Card invalid");
      }
    },

    resetAndRemoveCard() {
      this.donation = false;
      this.processing = false;
      this.success = false;
      this.cardValid = false;
      this.cardSource = undefined;
      if (this.cardElem)
        this.cardElem.destroy();
    },

    buildData(payment_id) {
        let data = {};
        Object.keys(this.form).forEach((key) => {
          if (key === 'payment') {
            data[key] = payment_id
        } else if (key === 'giftAidConsent') {
            data[key] = this.form[key].value ? 1 : 0;
        } else if (this.form[key].type === 'rich_text') {
        } else if (key && key !== 'undefined') {
            data[key] = this.form[key].value
          }
        })
        Object.keys(this.extraFields).forEach((key) => {
          if (key !== 'undefined')
            data[key] = this.extraFields[key]
        })
        if (this.addRecaptcha) {
            data['g-recaptcha-response'] = this.recaptchaToken
        }
        return data
    },

    submit() {
      this.showErrors = true;
      if (Object.keys(this.fieldErrors).length) {
        console.log(this.fieldErrors);
        return;
      }
      if (this.processing)
        return
      this.processing = true;
      this.submissionErrors = []
      //this.processing = true;
      console.log("Checking recaptcha");
      const start = this.addRecaptcha ? fetchRecaptcha() : new Promise();
      Promise.resolve(start).then(token => {
          //console.log(token)
          if (token) {
              this.recaptchaToken = token;
          }
          //console.log("Creating a source", this.cardSourceDetails)
          return new Promise((resolve, reject) => {
              if (this.reuse) {
                  this.stripe.createToken(this.cardElem)
                  .then(result => {
                      resolve({
                          error: result.error,
                          id: result.error ? undefined : result.token.id,
                      })
                  })
              } else {
                  this.stripe.createPaymentMethod('card', this.cardElem)
                  .then(result => {
                      resolve({
                          error: result.error,
                          id: result.error ? undefined : result.paymentMethod.id,
                      })
                  })
              }
          })
      })
      .then(result => {
        console.log("Got a result");
        if (result.error) {
          this.processing = false;
          this.errorOccurred = true;
        } else {
          console.log("Success!", result.id);
          let data = this.buildData(result.id);
          console.log("About to submit for payment")
          this.submitting = true;
          EventBus.$emit('craft:post', {
            callback: this.craftResponse,
            action: this.extraFields['action'] || '/',
            override: window.location,
            headers: {
                Accept: '*/*',
                'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                'X-Requested-With': 'XMLHttpRequest'
            },
            data,
          })
        }
      })
      .catch(err => {
          console.log(err)
          this.processing = false;
          this.errorOccurred = true;
      })
    },

    async craftResponse(resp) {
      this.submitting = false
      try {
          let data = await resp.json();
          if (resp.ok) {

             if (data.formErrors.length) {
                 this.processing = false;
                 this.submissionErrors = data.formErrors
                 return
             }

             if (!data.success) {
                 this.submitting = true;
                 return this.confirmPayment(data);
             }
            this.success = true;

            console.log(resp)
            this.$emit('success', {
              resp,
              values: this.buildData(),
            })
          } else {
              this.$emit('failed', {
                resp,
              })
          }
      }
      catch (error) {
          this.$emit('failed', {
              resp
          })
      }
      finally {
          this.processing = false;
      }
  },

  confirmPayment(data) {
      console.log("Not finished yet!")
      console.log(data);
      try {
          let action = data.actions[0]
          let resub = this.buildData(action.metadata.subscription ? action.metadata.subscription.id : action.metadata.payment_intent.id)
          new Promise((resolve, reject) => {
              resolve(action.metadata.subscription ?
                this.stripe.handleCardPayment(action.metadata.payment_intent.client_secret, this.cardElem):
                this.stripe.handleCardAction(action.metadata.payment_intent.client_secret))
          })
          .then(result => {
              this.processing = false;
              console.log(result)
              if (result.error) {
                  this.$emit('failed', {
                    resp: result,
                  })
              } else {
                  EventBus.$emit('craft:post', {
                    callback: this.craftResponse,
                    action: this.extraFields['action'] || '/',
                    override: window.location,
                    headers: {
                        Accept: '*/*',
                        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    data: resub,
                  })
              }
          })
      } catch (error) {
          this.$emit('failed', {
            error,
          })
      }
  }
}
};
</script>
